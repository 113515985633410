import Slider from "react-slick";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { FcGallery } from "react-icons/fc";
import { useRef } from "react";

import ProjectSliderCard from "./ProjectSliderCard";

import { EMPTY_ARRAY } from "../../../../../assets/constants";

// import WishListBtn from './../../../../globalComponents/WishListBtn/WishListBtn';

const ProjectSlider = ({ imagesData = EMPTY_ARRAY }) => {
  const settings = {
    // dots: true,
    lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 2,
  };

  const sliderRef = useRef(null);

  const handlePreviousBtn = () => sliderRef.current.slickPrev(); // Previous button logic
  const handleNextBtn = () => sliderRef.current.slickNext();
  const totalImages = imagesData?.length;

  return (
    <div className="slider-container">
      <Slider {...settings} ref={sliderRef}>
        {imagesData?.map((imgData, idx) => (
          <ProjectSliderCard imageData={imgData} key={idx} />
        ))}
      </Slider>
      <div className="controlBtnBox">
        <div className="leftBtn" onClick={handlePreviousBtn}>
          <FaChevronLeft />
        </div>
        <div className="rightBtn" onClick={handleNextBtn}>
          <FaChevronRight />
        </div>
      </div>

      <div className="controlBtn">
        <div className="shareBtn"></div>

        <div className="d-flex">
          <div className="sliderImgCountBox">
            <FcGallery fontSize={"1.5rem"} className="galleryIconImg" />{" "}
            {totalImages}
            Photos
          </div>
          {/* <div>Wishlist</div> */}
        </div>
      </div>
    </div>
  );
};

export default ProjectSlider;
