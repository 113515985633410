import { ImLocation2 } from "react-icons/im";
import { FaCircleCheck } from "react-icons/fa6";
import { BsBuildings } from "react-icons/bs";
import { PiCurrencyInrFill } from "react-icons/pi";

import ButtonDarkBlue from "../../../../globalComponents/molecules/ButtonDarkBlue";
import Heading from "../../../../globalComponents/molecules/Heading";

import "./projectDetailsSection.css";

const ProjectDetailsSection = ({ projectDetail }) => {
  const { projectName, address, regNo, minPrice, maxPrice, configuration } =
    projectDetail;

  return (
    <div className="row m-0 py-2 px-lg-3 px-md-3 px-0">
      <Heading
        tag="h1"
        text={projectName}
        color="#0B2C3D"
        fontSize="1.75rem"
        fontWeight="700"
        className="mt-0 mt-lg-3 mt-md-3"
      />

      <div className="row px-0 mx-0">
        <div className="col-md-6">
          <ImLocation2 className="projectDetailsIcons" />
          <span>{address}</span>
        </div>
        <div className="col-md-6">
          <div className="center-item">
            <BsBuildings className="projectDetailsIcons" />
            <span className="lineClamp1" title={configuration || ""}>
              {configuration && configuration.split("|").join(",")}
            </span>
          </div>
        </div>
        <div className="col-md-6">
          <FaCircleCheck className="projectDetailsIcons" />
          <span>RERA : </span> {regNo}
        </div>
        <div className="col-md-6">
          <PiCurrencyInrFill className="projectDetailsIcons" />
          <span>
            {minPrice} - {maxPrice}
          </span>
        </div>
      </div>

      <div className="row px-0 mx-0 justify-content-center mt-2 mt-lg-3">
        <div className="col-lg-6 col-md-6"></div>
        <div className="col-6 col-lg-3 col-md-3">
          <ButtonDarkBlue
            name="Download PriceList"
            className="rounded-1"
            // style={{ minWidth: "auto" }}
          />
        </div>
        <div className="col-6 col-lg-3 col-md-3">
          <ButtonDarkBlue
            name="Download Brochure"
            className="rounded-1"
            // style={{ minWidth: "auto" }}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectDetailsSection;
