import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../../../assets/constants";
import ProjectSlider from "./ProjectImageSlider/ProjectSlider";

const FloorPlans = ({ projectData }) => {
  const floorPlansImages = extractImageAttributes(
    projectData?.Floor_Plan?.Images?.data
  );

  return (
    <div className="">
      <ProjectSlider imagesData={floorPlansImages} />
    </div>
  );
};

export default FloorPlans;

function extractImageAttributes(imageDataArray = EMPTY_ARRAY) {
  return imageDataArray?.map((item = EMPTY_OBJECT) => {
    const ID = item?.id || "";
    const {
      url: imgUrl = "",
      alternativeText = "",
      caption: imgTitle = "",
    } = item?.attributes || EMPTY_OBJECT;

    return {
      id: ID,
      img: imgUrl,
      alt: alternativeText,
      title: imgTitle,
    };
  });
}
