import { postMethod } from "../../services";
import { EMPTY_OBJECT } from "./index";

const ZOHO_API_URL =
  "https://bold-approval-c005df0fb8.strapiapp.com/api/zoho-form";
// "http://localhost:1337/api/zoho-form";
// const OWNER_ID = 3664837000038157001; // replace key

export const submissionOnZoho = async (formData = EMPTY_OBJECT) => {
  const zohoUpdatedParams = getZohoParams(formData);
  // const params = new URLSearchParams(zohoUpdatedParams);
  // const URL = `${ZOHO_API_URL}?${params.toString()}`;
  await postMethod(
    ZOHO_API_URL,
    zohoUpdatedParams,
    "Form Successfully Submitted "
  );
};

export const getZohoParams = ({
  formName = "Project_Name",
  countryCode = "",
  name,
  lastName = "",
  phone = null,
  email = null,
  budget = null,
  propertyType,
  location = null,
  message = "Details",
  cityName = "",
}) => {
  return {
    // Contact_ID: "123",
    Last_Name: name + lastName,
    Email: email,
    Mobile: `${countryCode} ${phone}`,
    Query: message,
    Locationn: cityName || location,
    Budget: budget,
    Lead_Source1: "Website_lead",
    Lead_Type: "ONLINE", //whatsapp/ PhoneCall Type
    Rating: "Hot",
    Lead_Priority: "Fresh",
    Stage: "Fresh",
    Temp_Field_3: formName, // form Name
  };
};
