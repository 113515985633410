import { useRef, useState } from "react";

import Heading from "../../../../globalComponents/molecules/Heading";
import AboutBuilder from "../AboutBuilder";
import Amenities from "../Amenities";
import Faqs from "../Faqs";
import FloorPlans from "../FloorPlans";
import MapConnectivity from "../MapConnectivity";
import Overview from "../Overview";
import Specifications from "../Specifications";
import Disclaimer from "../Disclaimer";

import useApiFetcher from "../../../../../hooks/useApiFetcher";
import { getPropertyPopulatedFieldsUrlById } from "../../../../../assets/constants/apiUrls";
import "./projectDetailsBox.css";

const componentMap = {
  Overview: Overview,
  Amenities: Amenities,
  "Locations & Landmarks": MapConnectivity,
  //   Brochure: Brochure,
  //   "Price List": PrizeList,
  "Floor Plans": FloorPlans,
  Specifications: Specifications,
  "About Builder": AboutBuilder,
  "FAQ'S": Faqs,
  Disclaimer: Disclaimer,
};

const projectDetailsButtons = Object.keys(componentMap);

const ProjectDetailsBox = ({ projectId }) => {
  const url =
    getPropertyPopulatedFieldsUrlById(
      [
        "builder",
        "Floor_Plan",
        // "Add_Price_List",
        "category",
        "Specifications",
        "Location_Map",
        "Near_Areas",
        // "Brochures",
        "Faqs",
        "amenities][populate][amenity_category",
        "amenities",
      ],
      projectId
    ) + "populate[amenities][populate][Icon]=*";

  const [initialTabBtn, setTabBtn] = useState("Overview");
  const sectionRef = useRef({});
  const stickyNavBarRef = useRef(null);
  // const elementSize = useGetElementSize(stickyNavBarRef);

  const handleScrollToSection = (sectionName) => {
    const heightOfStickyNavBar = 42 + 112; //navBarHeight + Additional 112px offset
    setTabBtn(sectionName);
    const sectionElement = sectionRef.current[sectionName];

    if (sectionElement) {
      const elementPosition =
        sectionElement.getBoundingClientRect().top + window.pageYOffset;

      const offsetPosition = elementPosition - heightOfStickyNavBar;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const [projectData, isLoading, error] = useApiFetcher(url);

  if (error) return <div>{error}</div>;
  if (!projectData || isLoading) return <div>Loading...</div>;

  // const availableSections = getAvailableSectionsData(projectData);

  const PROJECT_NAME = projectData?.attributes?.Project_Name;
  const SECTIONS_WITHOUT_PROPERTY_NAME = [
    "Disclaimer",
    "About Builder",
    "Locations & Landmarks",
  ];

  return (
    <section className="my-5 projectDetailsBox">
      {/* Sticky NavBar */}
      <div ref={stickyNavBarRef} className="stickyProjectNavBar">
        {projectDetailsButtons?.map((btnName, idx) => {
          return (
            <div
              key={idx}
              className={`projectNavBarBtn ${
                btnName === initialTabBtn && "projectNavBarBtn_Active"
              }`}
              onClick={() => {
                handleScrollToSection(btnName);
                // handleStickyBarBtn(btnName);
              }}
            >
              {btnName}
            </div>
          );
        })}
      </div>

      {/* Sections */}
      <div className="">
        {projectDetailsButtons?.map((componentName, idx) => {
          const ComponentName = componentMap[componentName];

          const shouldDisplayProjectName =
            !SECTIONS_WITHOUT_PROPERTY_NAME.includes(componentName)
              ? `Of The ${PROJECT_NAME}`
              : "";

          return (
            <div
              key={idx}
              className="container projectSectionWrapper my-4  p-lg-5 p-md-5"
              ref={(ele) => {
                if (ele) sectionRef.current[componentName] = ele;
              }}
            >
              <Heading
                text={`${componentName} ${shouldDisplayProjectName}`}
                fontSize="1.25rem"
                fontWeight="650"
                color="#0b2c3d"
                className="py-3 py-lg-0 py-md-0 py-sm-0"
              />
              <ComponentName projectData={projectData?.attributes} />
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default ProjectDetailsBox;

export function checkSectionDataAvailability(projectData = {}) {
  const {
    Description,
    amenities,
    Location_Map,
    Floor_Plan,
    Specifications,
    builder,
    Faqs,
    Project_Disclaimer,
  } = projectData?.attributes;

  const sectionAvailabilityMap = {
    Overview: Description.trim().length > 0,
    Amenities: amenities?.data.length > 0,
    "Locations & Landmarks": Location_Map,
    "Floor Plans": Floor_Plan?.Images?.data.length > 0,
    Specifications: Specifications.length > 0,
    "About Builder": builder.data,
    "FAQ'S": Faqs.length > 0,
    Disclaimer: Project_Disclaimer,
    // ResidenceVideo: Residence_Video
  };

  const checkSuccessfulKeysMap =
    getSuccessfulKeys(Object.keys(sectionAvailabilityMap)) || [];

  return Object.keys(checkSuccessfulKeysMap);

  function getSuccessfulKeys(keys) {
    return keys.reduce((acc, key) => {
      if (sectionAvailabilityMap[key]) {
        acc[key] = true;
      }
      return acc;
    }, {});
  }
}
