import { useState } from "react";

import ConvertStringToHtml from "../../../../globalComponents/ConvertStringToHtml";

import useDeviceSize from "../../../../../hooks/useDeviceSize";
import { EMPTY_OBJECT } from "../../../../../assets/constants";
import "./aboutBuilder.css";

const AboutBuilder = ({ projectData = EMPTY_OBJECT }) => {
  const isMobileSize = useDeviceSize();
  const [isExpand, setIsExpand] = useState(false);

  const {
    Builder_Disclaimer: builderDisclaimer,
    Builder_Experience: builderExperience,
    // Builder_Name: builderName,
    Total_Projects_of_Builder: totalProject,
    Builder_Image,
  } = projectData?.builder?.data?.attributes;

  const {
    alternativeText: alt = "",
    url: img = "",
    caption: title = "",
  } = Builder_Image.data.attributes || EMPTY_OBJECT;

  return (
    <section className="aboutBuilderContainer row">
      <div className="col-5  col-md-3">
        <div className="builderImgBox">
          <img
            className="img-fluid"
            src={
              img ||
              "https://bold-approval-c005df0fb8.media.strapiapp.com/wellgrow_infotech_12247_33635c9a80.avif"
            }
            alt={alt}
            title={title}
            loading="lazy"
          />
        </div>
      </div>

      {isMobileSize && (
        <div className="col-7 col-md-5 center-item">
          <BuilderExperience
            projectCount={totalProject}
            experience={builderExperience}
          />
        </div>
      )}

      <div className="col-md-9">
        <div className="py-2">
          {!isMobileSize && (
            <BuilderExperience
              projectCount={totalProject}
              experience={builderExperience}
            />
          )}
          <div
            className={` builderDescription ${
              isExpand && "builderDescription_active"
            } py-3`}
          >
            <ConvertStringToHtml htmlData={builderDisclaimer} />
          </div>
          <div className="d-flex justify-content-end">
            <button
              className="btn border"
              onClick={() => setIsExpand(!isExpand)}
            >
              {isExpand ? "Read Less" : "Read More"}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutBuilder;

const BuilderExperience = ({ projectCount, experience }) => (
  <div className="aboutBuilderInfo">
    {projectCount && <div className="labelProject">Total Project :- 32</div>}
    {experience && <div className="labelExperience">Experience :- 2 Years</div>}
  </div>
);
